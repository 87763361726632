/* Analytics constants */

export enum AnalyticsEvent {
    EVTNM_VISITED_WEDDING_VENUE_PROFILE = 'Visited: Wedding Venue Profile',
    EVTNM_VISITED_LANDING_PAGE = 'Visited: Landing page',
    EVTNM_CLICKED_SHOW_PHONE_NUMBER = 'Clicked: Show Phone Number',
    EVTNM_CLICKED_CALL_PHONE = 'Clicked: Call phone',
    EVTNM_CLICKED_FAVORITES = 'Clicked: Favorites',
    EVTNM_CLICK_PROFILE_RECOMMENDATION = 'Clicked: Venue Recommendation',
    EVTNM_CLICKED_VENUE_WEBSITE = 'Clicked: Venue Website',
    EVTNM_PROFILE_ACCORDION_EXPANDED = 'Clicked: Expand Accordion',
    EVTNM_PROFILE_PHOTO_SCROLLED = 'Scrolled: Profile Photo',
    EVTNM_PROFILE_CLICKED_PRICE_VENUE = 'Clicked: Price Venue',
    EVTNM_PROFILE_CLICKED_SOCIAL = 'Clicked: Profile Social Link',
    EVTNM_CLICKED_REQUEST_RFP = 'Clicked: Request RFP',
    EVTNM_VISITED_GUEST_ROOMS = 'Visited: Guest Rooms',
    EVTNM_VISITED_INQUIRY_FORM = 'Visited: Contact Venue Form',
    EVTNM_SUBMIT_INQUIRY = 'Submitted: Contact Wedding Venue Form',
    EVTNM_VISITED_QUOTE_FORM = 'Visited: Venue Quote Form',
    EVTNM_SUBMIT_QUOTE = 'Submitted: Venue Quote Form',
    EVTNM_VISITED_VENUE_LANDING = 'Visited: Venue Landing Page',
    EVTNM_VLP_EXIT_MODAL_SHOWN = 'Shown: VLP exit modal',
    EVTNM_VLP_EXIT_MODAL_DISMISSED = 'Dismissed: VLP exit modal',
    EVTNM_VLP_EXIT_MODAL_ALT_CTA = 'Clicked: VLP not venue',
    EVTNM_VLP_EXIT_MODAL_CTA = 'Clicked: VLP exit modal signup',
    EVTNM_VISITED_PARTNERS_PAGE = 'Visited: partner page',
    EVTNM_CLICKED_SIGN_UP_BUTTON = 'Clicked: VLP Sign Up Button',
    EVTNM_VISITED_ALL_STYLES_PAGE = 'Visited: All Styles Page',
    EVTNM_LOADED_HOTEL_ROOMS_SECTION = 'Loaded: Hotel rooms section',
    EVTNM_SOCIAL_TABLES_FLOOR_PLANS_SECTION = 'Loaded: Social Tables floor plans',
    EVTNM_SHOWN_GUEST_ROOM_RECOMMENDATIONS = 'Shown: guest room recommendations',
    EVTNM_HOTEL_SELECTED = 'Hotel selected',

    EVTNM_CLICKED_DW_LANDING_PAGE_LISTING = 'Clicked: landing page listing',
    EVTNM_CLICKED_DW_LANDING_PAGE_SEE_ALL = 'Clicked: landing page see all',
    EVTNM_CLICKED_DW_HERO_PHOTO = 'Clicked: landing page hero photo',
    EVTNM_CLICKED_DW_LANDING_PAGE_LINK = 'Clicked: landing page link',

    EVTNM_CLICKED_PARTNER_CTA = 'Clicked: partner page CTA',
    EVTNM_CLICKED_PARTNER_MODAL_CTA = 'Clicked: partner modal CTA',
    EVTNM_SHOWN_SIGN_UP_SUCCESS = 'Shown: VLP Sign Up Success',
    EVTNM_ERROR_SIGN_UP_ERROR = 'Error: VLP Sign Up Error',
    EVTNM_SHOW_REGISTRATION_MODAL = 'Visited: Registration modal',
    EVTNM_VISITED_INQUIRY_REG_MODAL = 'Visited: Post inquiry Registration Modal',
    EVTNM_VISITED_POST_OUTREACH = 'Visited: Post outreach recommendations screen',
    EVTNM_REGISTERED = 'Registered',
    EVTNM_INQUIRY_MODAL_CLOSE_CLICKED = 'Clicked: Close outreach form',
    EVTNM_CLICKED_POST_OUTREACH_NO_THANKS = 'Clicked: no thanks, post outreach',
    EVTNM_CLICKED_POST_OUTREACH_INQUIRE = 'Clicked: inquire, post outreach',
    EVTNM_CLICKED_POST_OUTREACH_STEP_1 = 'Clicked: explore hotel blocks',
    EVTNM_CLICKED_POST_OUTREACH_SUCCESS_BROWSE_MORE = 'Clicked: browse more, post outreach success',
    EVTNM_INQUIRY_MODAL_ABANDONMENT_SHOWN = 'Shown: Abandon outreach modal',
    EVTNM_INQUIRY_MODAL_ABANDONMENT_CTA = 'Clicked: Return to outreach form',
    EVTNM_INQUIRY_MODAL_ABANDONMENT_DISMISSED = 'Clicked: Leave abandon outreach modal',
    EVTNM_VISITED_WEDDING_VENUE_LIST_PAGE = 'Visited: Venues List Page',
    EVTNM_CLICKED_WEDDING_VENUE_LIST_LINK = 'Clicked: Venues List Link',
    EVTNM_CLICKED_WEDDING_VENUE_LIST_ALL = 'Clicked: Venues List -All Venues',
    EVTNM_CLICKED_BREADCRUMB = 'Clicked: breadcrumb',
    EVTNM_CLICKED_HEADER_ITEM = 'Clicked: Header item',
    EVTNM_CLICKED_LOCATIONS_ITEM = 'Clicked: Locations submenu',
    EVTNM_VISITED_SEE_NEARBY_HOTELS = 'Visited: hotel rooms intent CTA',
    EVTNM_VISITED_HOTEL_RECOMMENDATIONS = 'Visited: hotel recommendations',
    EVTNM_CLICKED_NO_HOTEL_RECOMMENDATIONS = 'Clicked: no hotel recommendations',
    EVTNM_VISITED_GR_RFP_FORM = 'Visited: guest rooms RFP form',
    EVTNM_SUBMIT_RFP = 'submitted: room block RFP',
    EVTNM_CLICKED_SEARCH = 'Clicked: Search',
    EVTNM_WEDDING_VENUE_DIRECTORY = 'Visited: Wedding Venue Directory',
    EVTNM_CLICKED_SEARCH_RESULT = 'Clicked: Search Result',
    EVTNM_CLICKED_VENUE_CURATION_BUCKET = 'Clicked: venue curation bucket',
    EVTNM_SHOWN_VENUE_CURATION_BUCKET = 'Shown: Curation buckets',
    EVTNM_CLICKED_MY_SAVED_ESTIMATES = 'Clicked: My Saved Estimates',
    EVTNM_CLICKED_RESET_FILTERS = 'Clicked: Reset Filters',
    EVTNM_VISITED_MAP_VIEW = 'Visited: Map Directory',
    EVTNM_CLICKED_FILTER = 'Clicked: Filter',
    EVTNM_CLICKED_DIRECTORY_PRICING_BUTTON = 'Clicked: Directory Pricing Button',
    EVTNM_CLICKED_PAGINATION_CONTROL = 'Clicked: Pagination Control',
    EVTNM_SUBMITTED_SEARCH_FILTERS = 'Submitted: Search Filters',
    EVTNM_VENUE_CARD_CTA_CLICK = 'VenueCard CTA Button Clicked',
    EVTNM_CLICKED_SHARE_VENUE = 'Clicked: Share',
    EVTNM_CLICKED_CONTACT_CTA = 'Clicked: Contact venue CTA',
    EVTNM_SHOWN_EMAIL_ALREADY_EXISTS = 'Shown: email already exists',
    EVTNM_VENUE_CARD_CTA_REQUEST_QUOTE = 'Clicked: Venue Quote Form',
    EVTNM_VENUE_CARD_CLICKED = 'Clicked: Venue Card',
    EVTNM_BUDGET_ESTIMATE_BANNER_SEEN = 'Show: Budget Estimate Banner shown',
    EVTNM_VISITED_RFP_EMAIL = 'visited: wedding spot RFP form',
    EVTNM_SUBMITTED_RFP_EMAIL = 'submitted: Wedding Spot RFP form',
    EVTNM_FLOOR_PLANS_DOWNLOAD = 'Clicked: Floor plans download',
    EVTNM_VISITED_SOCIAL_TABLES = 'Visited: Social Tables',
    EVTNM_CLICKED_SPECIFY_GUEST_ROOMS = 'Clicked: Specify guest rooms',
    EVTNM_SUBMITTED_ROOM_COUNT = 'Submitted: Room count',
    EVTNM_CLICKED_CONTACT_VENUE_CTA = 'Clicked: Contact Venue',
    EVTNM_PHOTOS_DELETED = 'Photos deleted',
    EVTNM_PHOTOS_UPLOADED = 'Photos uploaded',
    EVTNM_PUSHED_LIVE = 'Photos pushed live',
    EVTNM_VISITED_VENDOR_SIGN_UP = 'Visited: Vendor Sign Up Page',
    EVTNM_ERROR_VENUE_SIGN_UP_PG = 'Error: Venue Sign up pg',
    EVTNM_VISITED_WEDDING_SURVEY_FORM = 'Visited: Wedding Survey form',
    EVTNM_SUBMIT_SUCCESS_WEDDING_SURVEY_FORM = 'Submit Success: Wedding Survey form',
    EVTNM_FAIL_SUCCESS_WEDDING_SURVEY_FORM = 'Submit Fail: Wedding Survey form',
    EVTNM_CLICKED_SUBMIT_ON_PAGE_ONE_OF_TWO = 'Clicked: Submit on Page One of Two',
    EVTNM_CLICKED_VENUE_SIGN_UP = 'Clicked: venue sign up',
    EVTNM_SHOWN_VFC_PHOTO_LIMIT_BANNER = 'Shown: VFC Photo Limit Banner',
    EVTNM_CLICKED_VENUE_ADMIN_UPGRADE_BUTTON = 'Clicked: Venue Admin Upgrade Button',
    EVTNM_VISITED_SUCCESSFUL_SIGN_UP_VENUE = 'Visited: successful venue sign up',
    EVTNM_CLICKED_PINTEREST_PHOTOS = 'Clicked: Pinterest photos',
    EVNTM_CLICKED_EXPLORE_HOTELS_CTA_ON_PROFILE = 'Clicked: Explore hotels CTA on profile',
    EVNTM_CLICKED_EXPLORE_HOTELS_CTA_FROM_HOTELS_RECOMMENDATIONS = 'Clicked: Explore hotels CTA from hotel recommendations section',
    EVTNM_CLICKED_QUICKVIEW_CTA = 'Clicked: quick view CTA',
    EVTNM_CLICKED_SUBMIT_REQUEST_CTA = 'Clicked: submit request CTA',
    // Venue shopping cart events
    EVTNM_SUCCESSFUL_COUPLE_OUTREACH = 'Successful cart checkout',
    EVTNM_CLICKED_SELECT_VENUE_CTA = 'Clicked: select venue CTA',
    EVTNM_CLICKED_REMOVE_FROM_LIST_CTA = 'Clicked: remove from list CTA',
    EVTNM_VENUE_REMOVED_FROM_CART = 'Venue removed from cart',
    EVTNM_VENUE_ADDED_TO_CART = 'Clicked: Add to list',
    EVTNM_VENUE_REMOVE_FROM_CART = 'Clicked: Remove from list',
    EVTNM_CLICKED_SHOPPING_CART = 'Clicked: Open Shopping Cart',
    EVTNM_USER_LOCAL_STORAGE_ERROR = 'User local storage error',
    EVTNM_CLICKED_SHOPPING_CARD_SUBMIT_REQUEST_CTA = 'Clicked: Submit request CTA',
    EVTNM_CLICKED_SHOPPING_CARD_SUBMIT_MESSAGE_VENUE_CTA = 'Clicked: message venue CTA',

    // Venue shopping cart experience events
    EVTNM_LOADED_SHOPPING_CARD_EXPERIENCE = 'Loaded : cart help text pop-up',
    EVTNM_CLICKED_SHOPPING_CARD_EXPERIENCE_CLOSE = 'Clicked : close on cart help text pop-up',
    EVTNM_CLICKED_SHOPPING_CARD_EXPERIENCE_NEXT = 'Clicked : cart next on cart help text pop-up',
    EVTNM_SHOPPING_CART_TEXT_POPUP = 'Shopping cart help text pop-up ',

    // Hotel search events
    EVTNM_VISITED_HOTELS_HOME = 'Visited: hotel blocks homepage',
    EVTNM_CLICKED_HOTEL_LOCATIONS = 'Clicked: hotel blocks location',
    EVTNM_VISITED_HOTELS_SRP = 'Visited: hotel blocks SRP',
    EVTNM_CLICKED_HOTEL_CARD_QUICKVIEW = 'Clicked: hotel card quick view',
    EVTNM_CLICKED_HOTEL_CARD_CLOSEQUICKVIEW = 'Clicked: hotel card close quick view',
    EVTNM_CLICKED_HOTEL_CART_REQUEST_PROPOSAL = 'Clicked: hotel cart request proposal',

    // Home page
    EVTNM_SHOWN_HERO_IMAGE_AD_PLACEMENT = 'Shown: Hero image ad placement',
    EVTNM_CLICKED_VIEW_VENUE_CTA = 'Clicked: View venue CTA',
    EVTNM_VISITED_WEDDING_SPOT_HOME_PAGE = 'Visited: Wedding Spot Home Page',
    EVTNM_CLICKED_AWARD_BANNER = 'Clicked: Award Banner',
    EVTNM_CLICKED_LOCATION_SUGGESTIONS = 'Clicked: Location Suggestions',

    // Checkout page
    EVTNM_SHOWN_INQUIRY_FORM_CHECKOUT = 'Shown : Inquiry form on checkout page',
    EVTNM_SUCCESSFUL_CART_CHECKOUT = 'Successful cart checkout',
    EVTNM_SHOWN_VENUE_RECOMMENDATIONS = 'Shown: venue recommendations',
    EVTNM_SHOWN_HOTEL_ROOM_RECOMMENDATIONS = 'Shown:  hotel room recommendations',
    EVTNM_SHOWN_HOTEL_ROOM_RFP_FORM = 'Shown: hotel RFP form',
    EVTNM_SUBMITTED_HOTEL_RFP_FORM = 'Submitted: hotel RFP form',

    // Reviews
    EVNTM_LOADED_VENUE_PROFILE_REVIEWS = 'Loaded: Venue Profile Reviews',
    EVTNM_SHOWN_ALL_REVIEWS = 'Shown All: Couple Reviews',

    // SE Events
    EVTNM_VISITED_PRICING_STEP = 'Visited: pricing step',
    EVTNM_CLICKED_SHARE_SPOT_ESTIMATE_CTA = 'Clicked: share estimate CTA',
    EVTNM_SENT_SHARE_SPOT_ESTIMATE_CTA = 'Sent: share estimate CTA',

    //Review Form
    EVTNM_SEARCH_REVIEW_FORM_SUBMITTED = 'Submitted : Search Review form submitted',
    EVTNM_DROPDOWN_REVIEW_FORM_SUBMITTED = 'Submitted : Dropdown Review form submitted',
    EVTNM_LOADED_REVIEW_FORM = 'Loaded: Review Form'
}

export enum UserProp {
    TESTLBL_PROFILE_V2 = 'Test Label: Profile V2',
    TESTLBL_PROFILE_VERSION = 'Test Label: Profile Version',
    TESTLBL_SHOW_TRAVEL_NOTIFICATIONS = 'Test Label: Show Travel Notifications',
    TESTLBL_ADD_PRICE_VENUE_BUTTON_TO_PROFILE = 'Test Label: Add Price Venue Button To Profile',
    TESTLBL_TEST_FLAG_DETAILS = 'Test Label: Test Flag Details',
    TESTLBL_TEST_FLAG_PERSONALIZED = 'Test Label: Test Flag Personalized',
    TESTLBL_TEST_POST_OUTREACH_PUSH_2 = 'Test label: post outreach push iteration-2',
    TESTLBL_TEST_SIMPLIFY_OUTREACH = 'Test Label: reduced outreach fields',
    TESTLBL_TEST_SIMPLIFY_OUTREACH_2 = 'Test Label: reduced outreach fields-2',
    TESTLBL_TEST_PROFILE_PAGE_CTA = 'Test Label: Profile Page CTA',
    TESTLBL_TEST_GUEST_ROOMS_INTENT_CTA = 'Test Label: Guest Rooms Intent CTA',
    TESTLBL_HOTELS_INTENT_CTA_STEP_REMOVED = 'Test Label: hotels intent CTA step removed',
    TESTLBL_SHOPPING_CART_FOR_WEDDING_VENUES = 'Test Label: Shopping cart for wedding venues',
    TESTLBL_CHECKOUT_PAGE_FOR_SHOPPING_CART = 'Test Label: Checkout page for shopping cart',
    TESTLBL_SRP_WITHOUT_FAVORITE_BUTTON = 'Test Label: SRP without favorite button',
}

export enum EventLocation {
    INQUIRY_CONFIRMATION = 'Inquiry Confirmation',
    PROFILE = 'Profile',
    PROFILE_V3 = 'Profile V3',
}

export enum InquiryType {
    REHEARSAL_DINNER = 'Rehearsal Dinner',
    WEDDING = 'Wedding',
}

export enum ClarityTags {
    USER_REGISTERED = 'Registered',
    USER_TYPE = 'User Type',
    HOTELS_SHOPPING_CARD = 'Hotels Shopping Cart',
    VENUES_SHOPPING_CARD = 'Venues Shopping Cart',
    POST_OUTREACH_FLOW = 'Post Outreach Flow',
    VENUE_PROFILE_PAGE_VERSION = "Venue Profile Page Version",
    SERVICE_NAME = "Service Name"
}

export enum EventParams {
    VENUE_ID = 'VenueId',
    METRICS_METATPYE = 'Metrics Metatype',
    LOCATION = 'Location',
    POSITION = 'Position',
    INQUIRY_TYPE = 'InquiryType',
}
