/* Amplitude backend analytics wrapper */
import { IS_NODE_SERVER, IS_DEV_ENV } from './config';
import { AnalyticsBackend } from './types';
import { AnalyticsEvent } from './constants';

// tslint:disable-next-line:no-any
declare const pendo: any | undefined;

const DEFAULT_VISITOR_ID = 'VISITOR-UNIQUE-ID';
const DEFAULT_ACCOUNT_ID = 'WEDDING_SPOT';



interface PendoInstance {
    identify: (options: object) => void;
    initialize: (options: object) => void;
    updateOptions: (options: object) => void;
    track: (event: string, data: object) => void;
    getAccountId: () => void;
    getSerializedMetadata: () => void;
}


const getPendoInstance = async () => {
    
    const getOrCreatePendoInstance = async () => {
        if (typeof pendo !== 'undefined' && pendo.isReady) {
            if (!pendo.isReady()) {
                pendo.initialize({
                    account: {id: DEFAULT_ACCOUNT_ID},
                    visitor: {id: DEFAULT_VISITOR_ID},
                });
            }
            return pendo;
        } else {
            return await new Promise<PendoInstance>((resolve) => {
                setTimeout(() => resolve(getOrCreatePendoInstance()), 100);
            });
        }
    };

    if (!IS_NODE_SERVER) {
        return await getOrCreatePendoInstance();
    } else {
        return {
            getAccountId: () => {},
            getSerializedMetadata: () => {},
            identify: (options => ({})),
            initialize: (options) => ({}),
            track: (event, {}) => ({}),
            updateOptions: (options) => ({}),
        };
    }
};


const PendoBackend: AnalyticsBackend = {

    identifyCurUser: async (id: string | number, accountId?: string | number) => {
        const pendoInstance = await getPendoInstance();
        const params = {account: {id: accountId || id}, visitor: {id}};

        await pendoInstance.identify(params);
        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`pendo.identify(${JSON.stringify(params)})`);
        }
    },

    logEvent: async <T>(eventName: AnalyticsEvent, params: T, callback?: () => void, label?: string) => {
        const pendoInstance = await getPendoInstance();

        pendoInstance.track(eventName, params);
        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`pendo.track(${eventName}, ${JSON.stringify(params)})`);
        }
    },
    setCurUserProps: async (props: object) => {
        const pendoInstance = await getPendoInstance();

        const oldProps = pendoInstance.getSerializedMetadata();
        const updatedProps = {
            account: {id: DEFAULT_ACCOUNT_ID},
            visitor: {...oldProps.visitor, ...props}
        };
        pendoInstance.updateOptions(updatedProps);
        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`pendo.updateOptions(${JSON.stringify(updatedProps)})`);
        }
    },
    setCurUserPropsOnce: (props: object) => {},
};

export default PendoBackend;
