/* Wrapper module for logging to various analytics backends */
/* Make sure we import the config to make sure initialization code is run */
import './config';

import { AnalyticsBackend } from './types';
import AmplitudeBackend from './amplitude';
import GABackend from './ga';
import WSBackend from './ws';
import ClarityBackend from './clarity';
import PendoBackend from './pendo';

import { AnalyticsEvent, UserProp, EventLocation, InquiryType, ClarityTags, EventParams } from './constants';
export { AnalyticsEvent, UserProp, EventLocation, InquiryType, ClarityTags, EventParams };

export const backends: AnalyticsBackend[] = [AmplitudeBackend, GABackend, WSBackend, PendoBackend];

export const logEvent = <T>(eventName: AnalyticsEvent, params: T, callback?: () => void, label?: string) => {
    // TODO: This is bad.. it calls the callback once for each backend
    backends.map((backend) => backend.logEvent(eventName, params, callback, label));
};

export const identifyCurUser = (id: string | number, externalId?: string | number) => {
    if (!externalId) {
        backends.map((backend) => backend.identifyCurUser(id));
    } else {
        backends.filter(backend => backend !== PendoBackend).map((backend) => backend.identifyCurUser(id));
        PendoBackend.identifyCurUser(externalId);
    }

};

export const setCurUserProps = (props: object) => {
    if (props.hasOwnProperty('name') && props.hasOwnProperty('value')) {
        // @ts-ignore
        if (props.name in ClarityTags) {
            ClarityBackend.setCurUserProps(props);
        }
        return;
    }
    backends.map((backend) => backend.setCurUserProps(props));
};

export const setCurUserPropsOnce = (props: object) => {
    backends.map((backend) => backend.setCurUserPropsOnce(props));
};
