/* Amplitude backend analytics wrapper */
import { GA_TRACKING_ID, GA4_MEASUREMENT_ID, IS_DEV_ENV, IS_NODE_SERVER, IS_TEST_ENV } from './config';
import { AnalyticsBackend } from './types';
import { AnalyticsEvent } from './constants';
import * as ReactGAUI from 'react-ga';
import ReactGA4 from 'react-ga4';

const gaBackends =  [ReactGAUI, ReactGA4];

ReactGAUI.initialize(GA_TRACKING_ID, { testMode: IS_TEST_ENV });
ReactGA4.initialize(GA4_MEASUREMENT_ID, { testMode: IS_TEST_ENV });

const pageviewEvents = [
    AnalyticsEvent.EVTNM_VISITED_WEDDING_VENUE_PROFILE,
    AnalyticsEvent.EVTNM_WEDDING_VENUE_DIRECTORY,
    AnalyticsEvent.EVTNM_VISITED_WEDDING_SPOT_HOME_PAGE,
];

// TODO: Remove this after validating bounce rate fixes
const filterChangeEvents = [AnalyticsEvent.EVTNM_SUBMITTED_SEARCH_FILTERS, AnalyticsEvent.EVTNM_CLICKED_PAGINATION_CONTROL];

const DIMENSIONS_MAP = {
    DESIGN_VERSION: 'dimension2',
    MARKET: 'dimension8',
};

const GABackend: AnalyticsBackend = {
    logEvent: <T>(eventName: AnalyticsEvent, params: T, callback?: () => void, label?: string) => {
        if (pageviewEvents.indexOf(eventName) !== -1) {
            let dimensions = {};

            // If market param is provided, add the market dimension to
            // all GA events sent for the lifetime of page
            const market = params['Market'];
            if (!!market) {
                dimensions[DIMENSIONS_MAP.MARKET] = market;
            }

            const version = params['Version'];
            if (!!version) {
                dimensions[DIMENSIONS_MAP.DESIGN_VERSION] = version;
            }

            if (Object.keys(dimensions).length > 0) {
                gaBackends.map(b => b.set(dimensions));
            }

            gaBackends.map(b => b.pageview(window.location.pathname + window.location.search));
            if (IS_DEV_ENV && !IS_NODE_SERVER) {
                console.log(`ga.pageview(${eventName})`);
                console.log(`ga4.pageview(${eventName})`);
            }
        }

        if (filterChangeEvents.indexOf(eventName) !== -1) {
            gaBackends.map(b => b.event({
                category: 'User Selections',
                action: 'Changed',
                label: 'SRP V2 Filter',
            }));
        }

        // TEMP: Log some events to GA to see if this impaces bounce rate
        if (eventName === AnalyticsEvent.EVTNM_PROFILE_PHOTO_SCROLLED) {
            gaBackends.map(b => b.event({
                category: 'Profile Image Carousel',
                action: 'Click',
                label: 'Scrolled Photo',
            }));
        }

        if (eventName === AnalyticsEvent.EVTNM_VISITED_INQUIRY_FORM) {
            gaBackends.map(b => b.event({
                category: 'Profile  Inquiry Modal',
                action: 'Click',
                label: 'Email Venue Directly',
            }));
        }

        if (eventName === AnalyticsEvent.EVTNM_CLICKED_VENUE_WEBSITE) {
            gaBackends.map(b => b.event({
                category: 'Venue',
                action: 'Click',
                label: 'Open Venue Website',
            }));
        }

        if (eventName === AnalyticsEvent.EVTNM_WEDDING_VENUE_DIRECTORY) {
            gaBackends.map(b => b.event({
                category: 'Directory',
                action: 'Visit',
                label: 'Visited directory page',
                nonInteraction: true,
            }));
        }

        if (eventName === AnalyticsEvent.EVTNM_CLICKED_SEARCH) {
            gaBackends.map(b => b.event({
                category: 'Search',
                action: 'Directory - Clicked Search',
                label: label,
            }));
        }

        if (callback !== undefined) {
            console.log('GA ANALITICS BACKEND CALLBACK fires immediately, not a real callback');
            callback();
        }
    },
    identifyCurUser: (id: string | number) => {},
    setCurUserProps: (props: object) => {},
    setCurUserPropsOnce: (props: object) => {},
};

export default GABackend;
