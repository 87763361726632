/* Amplitude backend analytics wrapper */
import { AMPLITUDE_API_KEY, IS_NODE_SERVER, IS_DEV_ENV } from './config';
import { AnalyticsBackend } from './types';
import { AnalyticsEvent } from './constants';
// from ws-components
import { StorableDataKeys, default as LocalStorage } from './storage';

/* Initialize amplitude global instance only in browser env */
let _inst;
let _amplitude;
if (!IS_NODE_SERVER) {
    _amplitude = require('amplitude-js');
    _inst = _amplitude.getInstance();

    // sending device_id kept in localStorage to keep tracking same user
    const amplitudeDeviceIdKey = StorableDataKeys.AMPLITUDE_DEVICE_ID;
    let amplitudeDeviceId = LocalStorage.getItem(amplitudeDeviceIdKey);
    let kwargs: amplitude.Config = {};
    if (!!amplitudeDeviceId) {
        kwargs.deviceId = amplitudeDeviceId;
    }
    _inst.init(AMPLITUDE_API_KEY, null, kwargs, (_inst: { options: { [key: string]: string } }) => {
        // save device_id to be able to track user across spa and legacy versions
        if (!amplitudeDeviceId) {
            LocalStorage.setItem(amplitudeDeviceIdKey, _inst.options['deviceId']);
        }
    });
} else {
    // Dummy amplitude
    _amplitude = { Identify: () => ({}) };
    _inst = {
        logEvent: () => ({}),
        setUserId: () => ({}),
        setUserProperties: () => ({}),
    };
}
const amplitude = _amplitude;
const amplitudeInstance = _inst;

const AmplitudeBackend: AnalyticsBackend = {
    logEvent: <T>(eventName: AnalyticsEvent, params: T, callback?: () => void, label?: string) => {
        amplitudeInstance.logEvent(eventName, params, callback);
        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`amplitude.logEvent(${eventName}, ${JSON.stringify(params)})`);
        }
    },
    identifyCurUser: (id: string | number) => {
        amplitudeInstance.setUserId(id);
        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`amplitude.setUserId(${id})`);
        }
    },
    setCurUserProps: (props: object) => {
        amplitudeInstance.setUserProperties(props);
        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`amplitude.setUserProperties(${JSON.stringify(props)})`);
        }
    },
    setCurUserPropsOnce: (props: object) => {
        var id = new amplitude.Identify();
        for (var key in props) {
            if (props.hasOwnProperty(key)) {
                id.setOnce(key, props[key]);
                if (IS_DEV_ENV && !IS_NODE_SERVER) {
                    console.log(`amplitude.identity.setOnce(${key}, ${props[key]})`);
                }
            }
        }
        amplitudeInstance.identify(id);
    },
};

export default AmplitudeBackend;
