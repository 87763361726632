/* Analytics module backend for Wedding Spot API-based analytics */
import { Venues, VendorsApi } from '@weddingspot/ws-api-client';

import { IS_NODE_SERVER, IS_DEV_ENV } from './config';
import { AnalyticsBackend } from './types';
import { AnalyticsEvent, EventLocation, EventParams, InquiryType } from './constants';

const handleApiError = (err: { detail?: string }) => {
    if (IS_DEV_ENV && !IS_NODE_SERVER && !!err && err.detail) {
        console.log(`analytics.ws.error: ${err.detail}`);
    }
};

const WSBackend: AnalyticsBackend = {
    logEvent: <T>(eventName: AnalyticsEvent, params: T, callback?: () => void, label?: string) => {
        switch (eventName) {
            case AnalyticsEvent.EVTNM_CLICKED_SHOW_PHONE_NUMBER:
                VendorsApi.logVenueMetric(
                    params[EventParams.VENUE_ID],
                    Venues.VenueMetricLogType.PHONE_CLICK,
                    params[EventParams.METRICS_METATPYE] || Venues.VenueMetricsMetaType.WEDDING_METRIC
                ).catch(handleApiError);
                break;
            case AnalyticsEvent.EVTNM_VISITED_WEDDING_VENUE_PROFILE:
                VendorsApi.logVenueMetric(
                    params[EventParams.VENUE_ID],
                    Venues.VenueMetricLogType.PROFILE_VIEW,
                    params[EventParams.METRICS_METATPYE] || Venues.VenueMetricsMetaType.WEDDING_METRIC
                ).catch(handleApiError);
                break;
            case AnalyticsEvent.EVTNM_CLICKED_VENUE_WEBSITE:
                VendorsApi.logVenueMetric(
                    params[EventParams.VENUE_ID],
                    Venues.VenueMetricLogType.WEBSITE_CLICK,
                    params[EventParams.METRICS_METATPYE] || Venues.VenueMetricsMetaType.WEDDING_METRIC
                ).catch(handleApiError);
                break;
            case AnalyticsEvent.EVTNM_CLICK_PROFILE_RECOMMENDATION:
                const loc = params[EventParams.LOCATION];
                const pos = params[EventParams.POSITION];
                const type = params[EventParams.INQUIRY_TYPE];
                const venueId = params[EventParams.VENUE_ID];

                let recLoc, reqType;
                if (loc === EventLocation.INQUIRY_CONFIRMATION) {
                    recLoc = Venues.InventoryType.INV_SUBTYPE_REC_APPT_CONFIRMATION;
                } else if (loc === EventLocation.PROFILE) {
                    recLoc = Venues.InventoryType.INV_SUBTYPE_REC_VENUEDETAILS;
                }

                if (type === InquiryType.REHEARSAL_DINNER) {
                    reqType = Venues.VenueMetricsMetaType.RD_METRIC;
                } else if (type === InquiryType.WEDDING) {
                    reqType = Venues.VenueMetricsMetaType.WEDDING_METRIC;
                }

                if (!!venueId && !!recLoc && !!reqType) {
                    VendorsApi.logVenueRecomendationsClickthrough({
                        venue: venueId,
                        recommended_from: recLoc,
                        metrics_metatype: reqType,
                        position: pos + 1,
                    });
                }
                break;
            default:
                break;
        }

        if (IS_DEV_ENV && !IS_NODE_SERVER) {
            console.log(`ws.logMetric(${eventName}, ${JSON.stringify(params)})`);
        }
    },
    identifyCurUser: (id: string | number) => {},
    setCurUserProps: (props: object) => {},
    setCurUserPropsOnce: (props: object) => {},
};

export default WSBackend;
