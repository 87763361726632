import { AnalyticsEvent } from './constants';
import { IS_NODE_SERVER } from './config';
import { AnalyticsBackend } from './types';

// tslint:disable-next-line:no-any
declare const clarity: any | undefined;

const ClarityBackend: AnalyticsBackend = {
    setCurUserProps: <T>(props: { name: string; value: string }) => {
        const { name, value } = props;
        if (!IS_NODE_SERVER) {
            // tslint:disable-next-line:no-console
            console.log(`Clarity.setCurUserProps(${name}, ${value})`);
        }
        if (typeof clarity !== 'undefined' && !IS_NODE_SERVER) {
            clarity('set', name, value);
        }
    },
    identifyCurUser(id: string | number): void {
        throw new Error('NotImplemented');
    },
    logEvent: <T>(eventName: AnalyticsEvent, params: T, callback?: () => void, label?: string) => {
        throw new Error('NotImplemented');
    },
    setCurUserPropsOnce(props: object): void {
        throw new Error('NotImplemented');
    },
};

export default ClarityBackend;
