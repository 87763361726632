/**
 * HOGAN_CONFIG values comes from the front-end service, e.g. ws-next/weddingspot-core-react(ws-render), that consumes this package.
 * 
 * ws-next has DefinePlugin configured in next.config.js, that simply replaces all occurence of HOGAN_CONFIG_FROM_FRONT_END_SERVICE with
 * actual hogan values, generated during build & deployment stage.
 * 
 * weddingspot-core-react (ws-render) has DefinePlugin configured packages/weddingspot-core-react/config/webpack/webpack.config.plugins.js
 * It replaces all occurence of HOGAN_CONFIG_FROM_FRONT_END_SERVICE with actual hogan values, generated at build deployment stage.
 * 
 * TOD0: We can use this HOGAN_CONFIG variable and can fetch the hoganized value for below hard-coded variables https://jira.cvent.com/browse/WS-3604
 * 
 * Refer https://webpack.js.org/plugins/define-plugin/ 
 */

// @ts-ignore
const HOGAN_CONFIG : HoganConfig = HOGAN_CONFIG_FROM_FRONT_END_SERVICE

export const IS_DEV_ENV = HOGAN_CONFIG.env === 'local';
export type IS_DEV_ENV = typeof IS_DEV_ENV;

// @ts-ignore
export const IS_STAGING_ENV = HOGAN_CONFIG.env === 'sg50';
export type IS_STAGING_ENV = typeof IS_STAGING_ENV;

// @ts-ignore
export const IS_ALPHA_ENV = HOGAN_CONFIG.env === 'ap50';
export type IS_ALPHA_ENV = typeof IS_ALPHA_ENV;

export const IS_PROD_ENV = !(IS_DEV_ENV || IS_STAGING_ENV || IS_ALPHA_ENV);
export type IS_PROD_ENV = typeof IS_PROD_ENV;

// Whether or not the app is being run on client or nodejs server
export const IS_NODE_SERVER = !(typeof window !== 'undefined' && window.document);
export type IS_NODE_SERVER = typeof IS_NODE_SERVER;

export const IS_TEST_ENV = process.env.NODE_ENV === 'test';
export type IS_TEST_ENV = typeof IS_TEST_ENV;

export const APP_ROOT_DOM_NODE_ID = 'ws-react-app';
export type APP_ROOT_DOM_NODE_ID = typeof APP_ROOT_DOM_NODE_ID;

export const SERVER_ADDRESS = IS_DEV_ENV ? 'http://localhost:8000' : '';
export type SERVER_ADDRESS = typeof SERVER_ADDRESS;

let config = {
    amplitude: HOGAN_CONFIG.amplitude.api_key,
    ga: HOGAN_CONFIG.google_analytics.id, // todo get different GA accounts
    ga4: HOGAN_CONFIG.google_analytics4.id,
};

export const AMPLITUDE_API_KEY = config.amplitude;
export type AMPLITUDE_API_KEY = typeof AMPLITUDE_API_KEY;

export const GA_TRACKING_ID = config.ga;
export type GA_TRACKING_ID = typeof GA_TRACKING_ID;

export const GA4_MEASUREMENT_ID = config.ga4;
export type GA4_MEASUREMENT_ID = typeof GA4_MEASUREMENT_ID;

export const META_ROBOTS = HOGAN_CONFIG.meta_robots;