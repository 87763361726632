// Same file locates in ws-core-components/src/utils
const stringify = (obj: any): string => JSON.stringify(obj);
const parse = (data: string): any => JSON.parse(data);

function isLocalStorageAvailable() {
    try {
        return typeof localStorage !== 'undefined';
    } catch (e) {
        return false;
    }
}

function getItem(key: string, replacement: any = null) {
    if (!isLocalStorageAvailable()) {
        return null;
    }

    const item = localStorage.getItem(key);
    if (item === null) {
        return replacement;
    }
    try {
        return parse(item);
    } catch (e) {
        console.warn('Cannot parse local storage item');
        return item;
    }
}

function setItem(key: string, value: any) {
    if (isLocalStorageAvailable()) {
        try {
            localStorage.setItem(key, stringify(value));
        } catch (e) {
            console.warn('Cannot set item to localStorage');
        }
    }
}

function removeItem(key: string) {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(key);
    }
}

// Enum which holds keys for items
// that are saved in localStorage or cookies
export enum StorableDataKeys {
    WS_APP_BANNER_LAST_DISMISSED = 'ws-app-banner-last-dismissed',
    ABANDON_MODAL_SHOWN_LS_KEY = '__VLPAbandonModalShown',
    INQUIRY_MODAL_ABANDONMENT_SHOWN = 'inquiryModalAbandonmentShown',
    AMPLITUDE_DEVICE_ID = 'amplitude_device_id',
    VISITED_VENUES = 'visitedVenues',
    IS_SEND_EMAIL_MODAL_SHOWN = 'isSendEmailModalShown',
    BUDGET_ESTIMATE_BANNER_SEEN = 'budgetEstimateBannerSeen',
}

export default { getItem, setItem, removeItem, isLocalStorageAvailable };
